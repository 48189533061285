import axios from 'axios';
import config from './config-fe.mjs';
var API_URL;
if (config.env === 'DEV') {
  API_URL = config.API_URL_DEV;
}
else if (config.env === 'PROD') {
  API_URL = config.API_URL_PROD;

}


export const fetchInvoiceDataFromAPI = async (formData, clientCode) => {

  try {
    const response = await axios.post(`${API_URL}/fetchInvoiceData`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "X-Client-Code": clientCode,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    throw error;
  }
};


export const validateManagerCode = async (managerCode) => {
  try {
    const response = await axios.post('/validate-manager', managerCode);
    console.log('response is :', response);
    return response.data; // Return the response data (e.g., { isValid: true })
  } catch (error) {
    console.error('Error in validateManagerCode API call:', error);
    throw error;
  }
};

export const getQuote = async (requestBody, clientCode) => {
  try {
    const response = await axios.post(`${API_URL}/getQuote`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        "X-Client-Code": clientCode,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};