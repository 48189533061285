import React, { createContext, useContext, useState } from 'react';

// Create a context
const AppContext = createContext();

// Create a provider component
export const AppProvider = ({ children }) => {
  const [clientCode, setClientCode] = useState('');
  const [isManagerValidated, setIsManagerValidated] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('export');

  return (
    <AppContext.Provider value={{ clientCode, setClientCode, isManagerValidated, setIsManagerValidated, selectedProduct, setSelectedProduct }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook for using the context
export const useAppContext = () => useContext(AppContext);
