import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import { fetchInvoiceDataFromAPI } from "../apiService";
import { validateManagerCode } from '../apiService';
import {  useInvoiceRowData } from './InvoiceRowDataContext';
import { useInvoiceFileData } from './InvoiceFileDataContext';
import { useAppContext } from '../AppContext';

const HomePage = () => {

  const [loading, setLoading] = useState(false);
  const { setRowData } = useInvoiceRowData();
  const { file, setFile } = useInvoiceFileData();
 

  const [managerCode, setManagerCode] = useState('');

  const { clientCode, setClientCode, isManagerValidated, setIsManagerValidated, selectedProduct, setSelectedProduct } = useAppContext();
  const navigate = useNavigate();

  const comboBoxOptions = [
    { displayValue: 'MATAN DIGITAL', actualValue: ' MATAN DIGITAL' },
    { displayValue: 'DSV', actualValue: ' DSV' },
    { displayValue: 'RONEL', actualValue: ' RONEL' },
  ];

  const productComboBoxOptions = [
    'Export',
    'Import',
    'Drop'
  ];

  const handleManagerValidation = async () => {
    try {
        console.log('managerCode is :', managerCode);
        const response = await validateManagerCode({ managerCode });
  
        if (response.isValid) {
            setIsManagerValidated(true);
            alert('Manager validated successfully!');
        }
    } catch (error) {
        if (error.response) {
            const { status } = error.response;
  
            if (status === 401) {
                setIsManagerValidated(false);
                setClientCode('');
                alert('Invalid manager code.');
            } else {
                console.error('Unexpected error while validating manager:', error.response);
                alert(`An unexpected error occurred. Status: ${status}`);
            }
        } else {
            console.error('Network or server error while validating manager:', error);
            alert('A network or server error occurred. Please try again later.');
        }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the selected file
    if (selectedFile && selectedFile.type === 'application/pdf') {
       // Update the file state in the context
       setFile(selectedFile);
    } else {
      setFile(null);
      alert('Please select a PDF file');

      const emptyRowData = {
        delivery_company: 'Delivery Company Name',
            delivery_address: 'Delivery Company Address',
            delivery_city: 'Delivery Company City',
            delivery_country: 'Delivery Company Country',
            delivery_postcode: 'Delivery Company Postcode',
            delivery_contact: 'Delivery Company Contact',
            delivery_phone: 'Delivery Company Phone',
            collection_company: 'Collection Company',
            collection_address: 'Collection Address',
            collection_city: 'Collection City',
            collection_country: 'Collection Country',
            collection_postcode: 'Collection Postcode',
            collection_contact: 'Collection Contact',
            collection_phone: 'Collection Phone',
            consignment_contents_type: 'Consignment Contents Type',
            consignment_contents: 'Consignment Contents',
            consignment_value: 0,
            consignment_currency: 'Consignment Currency',
            consignment_origin_country: 'Consignment Origin Country',
            dimensions_weight: 0,
            dimensions_length: 0,
            dimensions_width: 0,
            dimensions_height: 0
      };
      setRowData(emptyRowData);

    }
  };

  

  const navigateValidateInvoice = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('pdfInvoiceFile', file);
    try {
      setLoading(true);
      const data = await fetchInvoiceDataFromAPI(formData, clientCode);
      setRowData(data); // Update the data in the context
    } catch (error) {
      alert(error.message)
    }
    finally {
      setLoading(false);
      //state clientCode can be removed as using the useAppContext in the Validate Page 
      navigate('/validate', { state: { clientCode} });
    }
  }
 /*  else {
    alert('You need a PDF file');
  }; */
  }


   return (
    <>
       <h1>Lutrra DynamoAI Courier Booking</h1>
       
       {/* Manager validation section */}
       <div className="manager-section">
         <h2>Manager Validation</h2>
         
           <input
             type="password"
             name="managerCode"
             placeholder="Enter manager code"
             value={managerCode}
             onChange={(e) => setManagerCode(e.target.value)}
           />
           <button onClick={handleManagerValidation}>
             Login as Manager
           </button>
         

         {/* Combo box visible only if validated */}
         {isManagerValidated && (
           <div>

             <select
               id="managerComboBox"
               value={clientCode}
               onChange={(e) => setClientCode(e.target.value)}
             >
               <option value="" disabled>Select Client</option>
               {comboBoxOptions.map((option) => (
                 <option key={option.displayValue} value={option.actualValue}>
                   {option.displayValue}
                 </option>
               ))}
             </select>
           </div>
         )}
       </div>
        
        <p>
            Welcome to the Lutrra DynamoAI Courier Booking Page 
                
        </p>
        <p>
            According to your Client Code you can now upload your Commercial Invoice PDF file to validate the invoice details, and proceed with quotes and booking with the courier companies.

        </p>
        <br></br><br></br>
      
       <div>
       <label className="sr-only">
          Product

        </label>
        <br></br>
         <select
           id="productComboBox"
           value={selectedProduct}
           onChange={(e) => setSelectedProduct(e.target.value)}
         >
           {productComboBoxOptions.map((option) => (
             <option key={option} value={option.toLowerCase()}>
               {option}
             </option>
           ))}
         </select>
       </div>

      <div>
        <label htmlFor="file" className="sr-only">
          Choose your invoice PDF
          
        </label>
        <br></br>
        <input id="file" type="file" accept=".pdf" onChange={handleFileChange} />
    

        {file ? (
        <div>
          <h2>Invoice File Details:</h2>
          <span>File Name: {file.name}</span>
          <br></br>
          {/* <span>File Type: {file.type}</span> */}
          <br></br>
  
        </div>
      ) : null}
      <br></br>
      </div>
      

      <div>
      {file && (
        <>
          <button onClick={navigateValidateInvoice} disabled={loading}>
            Validate Invoice
          </button>
          {loading && <p>Loading...</p>}
        </>
      )}
    </div>
    </>
  );
};

export default HomePage;